import { __ } from '@wordpress/i18n'
import { useContext } from 'react'
import AboutusScreenContext from '../Components/Context/AboutusScreenContext';
import DashboardScreenContext from '../Components/Context/DashboardScreenContext';
import FeedEditorContext from '../Components/Context/FeedEditorContext'
import SettingsScreenContext from '../Components/Context/SettingsScreenContext';
import SupportScreenContext from '../Components/Context/SupportScreenContext';

class SbUtils {

    /**
     *   Generates : CSS Class Names
    */
    static getClassNames = (props, slug, classesList) => {
        let classes = [slug, props.customClass];
        classesList.forEach(function (classItem) {
            if (typeof classItem === 'object') {
                classes.push(slug + '-' + (props[Object.keys(classItem)?.[0]] === undefined ? Object.values(classItem)?.[0] : props[Object.keys(classItem)?.[0]]));
            }
            if (props[classItem] !== undefined) {
                classes.push(slug + '-' + props[classItem]);
            }
        });
        return classes.join(' ');
    }

    /**
     *   Generates : Element HTML Attibutes
    */
    static getElementAttributes = (props, attributesList, ref) => {
        let attrs = [];
        attributesList.forEach(function (attrItem) {
            if (typeof attrItem === 'object') {
                attrs[`data-${Object.keys(attrItem)?.[0]}`] = props[Object.keys(attrItem)?.[0]] === undefined ? Object.values(attrItem)?.[0] : props[Object.keys(attrItem)?.[0]];
            }
            else if (props[attrItem] !== undefined && props[attrItem] !== null) {
                if (attrItem === 'disabled') {
                    attrs[`${attrItem}`] = props[attrItem];
                } else {
                    attrs[`data-${attrItem}`] = props[attrItem];
                }
            }
        });
        return attrs;
    }

    static goToLink = (link, target) => {
        return () => {
            window.open(link, target);
        }
    }
    /**
     *  Generates : Element React Actions
    */
    static getElementActions = (props, actionsList, ref) => {
        if (props?.link) {
            return {
                "onClick": SbUtils.goToLink(props?.link, props?.target || '_self')
            }
        } else {
            let attrs = [];
            actionsList.forEach(function (actItem) {
                if (props[actItem] !== undefined) {
                    attrs[`${actItem}`] = props[actItem];
                }
            });
            return attrs;
        }
    }

    /**
     * Generates : Element React Styles
     */
    static getElementStyles = (props, stylesList) => {
        let styles = {};
        stylesList.forEach(function (styleItem) {
            if (props[styleItem] !== undefined) {
                styles[styleItem] = props[styleItem];
            }
        });
        return styles;
    }

    /**
     *   Check if Element is Empty/Undefined/Null
    */
    static checkNotEmpty = (value) => {
        return value !== undefined && value !== 'undefined' && value !== null && value?.toString()?.replace(/ /gi, '') !== '';
    }

    /**
     *   Ajax Post Action
    */
    static ajaxPost = (ajaxHandler, data, callback, topLoader = null, editorNotification = null, notificationsContent = null) => {

        //Set Form Data body for the Ajax call
        var formData = new FormData();
        for (var key in data) {
            formData.append(key, data[key]);

        }
        if (data['nonce'] === undefined) {
            formData.append('nonce', window?.sb_customizer?.nonce);
        }

        topLoader !== null && topLoader.setLoader(true); //Show top Bar header loader

        fetch(ajaxHandler, {
            method: 'POST',
            credentials: 'same-origin',
            body: formData
        })
            .then(response => response.json())
            .then((data) => {

                callback(data); //CallBack Function
                topLoader !== null && topLoader.setLoader(false); //Hide top Bar header loader

                //Show Success Notification if Set
                (editorNotification !== null && notificationsContent?.success)
                    && SbUtils.applyNotification(notificationsContent.success, editorNotification);


            }).catch(error => {
                //Show Error Notification if Set
                topLoader !== null && topLoader.setLoader(false); //Hide top Bar header loader
                (editorNotification !== null && notificationsContent?.error)
                    && SbUtils.applyNotification(notificationsContent.error, editorNotification);

            })
    }


    /**
     *   Notification Process
     *
    */
    static applyNotification = (notification, editorNotification) => {
        editorNotification.setNotification({
            active: true,
            type: notification?.type,
            icon: notification?.icon,
            text: notification?.text
        });
        setTimeout(function () {
            editorNotification.setNotification({
                active: false,
                type: null,
                icon: null,
                text: null
            });
        }, notification?.time ? notification?.time : 3000);
    }

    /**
     *   Checks if CSS element is missing unit then add it
    */
    static addCssUnit = (value) => {
        if (SbUtils.checkNotEmpty(value)){
            value = value.toString().trim();
            if (value.match(/^[0-9]+$/))
                return `${value}px`;

            if (value.match(/^[0-9]+px$/))
                return value;

            if (value.match(/^[0-9]+em$/))
                return value;

            if (value.match(/^[0-9]+%$/))
                return value;

            if (value.match(/^[0-9]+vh$/))
                return value;

            if (value.match(/^[0-9]+vw$/))
                return value;
        } else {
            return '0px';
        }
    }

    /**
     *   Find Element By ID
    */
    static findElementById = (objects, searchBy, value) => {
        let [objkey, obj] = Object.entries(objects).find(([key, ob]) => ob[searchBy] === value)
        return obj;
    }

    /**
     *   Find Nested Element
    */
    static findNestedElement = (objects, searchBy, value) => {
        if (typeof objects === 'object') {
            const objectResult = objects?.find((elem, ind) => {
                if (elem[searchBy] === value)
                    return elem;
                else {
                    let newElSearch = elem?.sections !== undefined ?
                        Object.entries(elem.sections) : elem;

                    if (typeof elem === 'string') {
                        newElSearch = Object.entries(objects[1]?.controls) ? Object.entries(objects[1]?.controls) : {}
                    }
                    return SbUtils.findNestedElement(newElSearch, searchBy, value)
                }
            });
            return objectResult;
        }
        return false;
    }



    /**
     *   Prints SVG Icon
    */
    static printIcon = (iconName, customClass = false, key = false, iconSize = undefined) => {
        const iconStyle = (iconSize !== undefined && { width: iconSize + 'px' }) || null;

        return window?.sb_customizer?.iconsList[iconName] ?
            <span
                key={key !== false ? key : key}
                className={(customClass !== false ? customClass : '') + (iconSize !== undefined ? ' sb-custom-icon-size' : '')}
                style={iconStyle}
                dangerouslySetInnerHTML={{ __html: window.sb_customizer.iconsList[iconName] }}></span>
            : '';
    }

    /**
     *  Check Control Conditions Hide/Show/Dimmed
    */
    static checkControlCondition = (element, feedSettings) => {
        let isPro = window?.sb_customizer?.isPro && window?.sb_customizer?.isPro ? true : false;
        if (element?.hidePro === true && isPro !== true) {
            return false
        } else {
            if (element?.condition === undefined) {
                return true;
            } else {
                let isConditionTrue = 0;
                Object.keys(element.condition).map((condition, index) => {
                    if (element.condition[condition].indexOf(feedSettings[condition]) !== -1)
                        isConditionTrue += 1
                });
                let showElement = isConditionTrue === Object.keys(element.condition).length;
                return showElement === false && element?.conditionDimmed === true ? 'dimmed' : showElement;

            }
        }

    }

    /*
        Get Feed Styling
    */
    static getFeedStyling = (feedSettings, customizerData, feedID) => {
        let styles = '';
        customizerData.forEach((tab) => {
            Object.values(tab.sections).map((section) => {
                styles += SbUtils.getFeedStylingRecursive(section, feedSettings, feedID);
            })
        })
        return styles;
    }


    /*
        Get Feed Styling Recursive
    */
    static getFeedStylingRecursive = (element, feedSettings, feedID) => {
        let styles = '';
        element?.controls.forEach(function (el) {
            let controlStyle = SbUtils.getSingleControlStyle(el, feedSettings, feedID);
            styles += controlStyle !== false ? controlStyle : '';

            //Nested List Elements
            if (el?.controls !== undefined)
                styles += SbUtils.getFeedStylingRecursive(el, feedSettings, feedID)
        });
        return styles;
    }

    /*
        Get Single Control Style
    */
    static getSingleControlStyle = (control, feedSettings, feedID) => {
        let applyStyle = SbUtils.checkControlCondition(control, feedSettings);
        if (control?.style === undefined || applyStyle === false)
            return false

        let styleString = '';
        let containerFeedId = '#sb-tiktok-feeds-container-' + feedID;
        Object.entries(control.style).map((css) => {
            let cssValue = SbUtils.createCssStyle(control.type, feedSettings[control?.id]);
            styleString += (cssValue !== null && cssValue !== undefined) ? `${containerFeedId + ' ' + css[0]}{${css[1].replace("{{value}}", cssValue)}}` : '';
        })
        return styleString;
    }

    /*
     *   Create Style
     *   This Will dump the CSS style depending on the Type
    */
    static createCssStyle = (type, value) => {

        switch (type) {

            // Create Box Shadow Styling
            case 'boxshadow':
                if (value?.enabled === undefined || value?.enabled === false)
                    return null;
                return `${SbUtils.addCssUnit(value.x)} ${SbUtils.addCssUnit(value.y)} ${SbUtils.addCssUnit(value.blur)} ${SbUtils.addCssUnit(value.spread)} ${value.color}`;

            // Create Box Radius Styling
            case 'borderradius':
                if (value?.enabled === undefined || value?.enabled === false)
                    return null;
                return value.radius + 'px';

            // Create Stroke Styling
            case 'stroke':
                if (value?.enabled === undefined || value?.enabled === false)
                    return null;
                return value.thickness + 'px solid ' + value.color;

            // Create Distance Styling : Margins/Paddings
            case 'distance':
                let sidesList = ['top', 'right', 'bottom', 'left'],
                    distances = '';
                sidesList.forEach(side => {
                    distances += SbUtils.checkNotEmpty(value[side]) ? `${value[side]}px ` : '0px ';
                });
                return distances;

            // Create Font Styling : Family/Weight/Size/height
            case 'font':
                let fontElements = ['family', 'weight', 'size', 'height'],
                    fonts = '';
                fontElements.forEach(f => {
                    let includeFont = SbUtils.checkNotEmpty(value[f]) && value[f] !== 'inherit';
                    fonts += includeFont ? `${f === 'height' ? 'line' : 'font'}-${f}:${value[f]}${f === 'size' ? 'px' : ''};` : '';
                });
                return fonts;

            default:
                return value;
        }
    }

    /*
     *   Transform Date & Print Date
    */
    static printDate = (postDate, feedSettings, sbCustomizer) => {
        let originalDate = postDate,
            dateOffset = new Date(),
            offsetTimezone = dateOffset.getTimezoneOffset(),
            translatedText = window.sb_customizer?.pluginSettings?.translations,
            periods = [
                translatedText?.second ? translatedText?.second : __('second', 'sb-customizer'),
                translatedText?.minute ? translatedText?.minute : __('minute', 'sb-customizer'),
                translatedText?.hour ? translatedText?.hour : __('hour', 'sb-customizer'),
                translatedText?.day ? translatedText?.day : __('day', 'sb-customizer'),
                translatedText?.week ? translatedText?.week : __('week', 'sb-customizer'),
                translatedText?.month ? translatedText?.month : __('month', 'sb-customizer'),
                translatedText?.year ? translatedText?.year : __('year', 'sb-customizer')
            ],
            periodsPlural = [
                translatedText?.seconds ? translatedText?.seconds : __('seconds', 'sb-customizer'),
                translatedText?.minutes ? translatedText?.minutes : __('minutes', 'sb-customizer'),
                translatedText?.hours ? translatedText?.hours : __('hours', 'sb-customizer'),
                translatedText?.days ? translatedText?.days : __('days', 'sb-customizer'),
                translatedText?.weeks ? translatedText?.weeks : __('weeks', 'sb-customizer'),
                translatedText?.months ? translatedText?.months : __('months', 'sb-customizer'),
                translatedText?.years ? translatedText?.years : __('years', 'sb-customizer')
            ],
            lengths = ["60", "60", "24", "7", "4.35", "12", "10"],
            now = dateOffset.getTime() / 1000,
            newTime = originalDate + offsetTimezone,
            printDate = '',
            dateFortmat = feedSettings.dateFormat,
            agoText = translatedText?.ago ? translatedText?.ago : __('ago', 'sb-customizer'),
            difference = null,
            formatsChoices = {
                '2': 'F jS, g:i a',
                '3': 'F jS',
                '4': 'D F jS',
                '5': 'l F jS',
                '6': 'D M jS, Y',
                '7': 'l F jS, Y',
                '8': 'l F jS, Y - g:i a',
                '9': "l M jS, 'y",
                '10': 'm.d.y',
                '11': 'm/d/y',
                '12': 'd.m.y',
                '13': 'd/m/y',
                '14': 'd-m-Y, G:i',
                '15': 'jS F Y, G:i',
                '16': 'd M Y, G:i',
                '17': 'l jS F Y, G:i',
                '18': 'm.d.y - G:i',
                '19': 'd.m.y - G:i'
            };
        if (formatsChoices[dateFortmat] !== undefined) {
            printDate = window.date_i18n(formatsChoices[dateFortmat], newTime);
        } else if (dateFortmat === 'custom') {
            let dateCustom = feedSettings.dateCustomFormat;
            try {
                printDate = window.date_i18n(dateCustom, newTime);
            } catch (error) {
                printDate = __('Unsported Format', 'sb-cutomzier');
            }

        }
        else {
            if (now > originalDate) {
                difference = now - originalDate;
            } else {
                difference = originalDate - now;
            }
            for (var j = 0; difference >= lengths[j] && j < lengths.length - 1; j++) {
                difference /= lengths[j];
            }
            difference = Math.round(difference);
            if (difference !== 1) {
                periods[j] = periodsPlural[j];
            }
            printDate = difference + " " + periods[j] + " " + agoText;
        }
        return printDate;
    }

    /*
     *   Print Post Text + Trim
    */
    static printText = (postText, feedSettings, lightbox=false) => {
        let text = postText,
            contentLength = feedSettings.contentLength;

        if (SbUtils.checkNotEmpty(postText) && text.length > contentLength && lightbox === false) {
            return (
                <>
                    {text.substring(0, contentLength)}
                    <a className='sb-post-readmorelink'
                    >
                        ...
                    </a>
                </>

            )
        } else {
            return (
                text
            )
        }
    }

    /*
    *   Recursive function to add more Highlighted Sections
    *
   */
    static getHighlightedSectionRecursive = (element) => {
        let highlightedSection = {};
        element?.controls.forEach(function (el) {
            if (el?.highlight !== undefined) {
                highlightedSection[el?.highlight] = el;
            }
            //Nested List Elements
            if (el?.controls !== undefined) {
                highlightedSection = {
                    ...highlightedSection,
                    ...SbUtils.getHighlightedSectionRecursive(el)
                }
            }
        });
        return highlightedSection;
    }

    /*
     *   Create Object for Highlighted Sections
     *
    */
    static getHighlightedSection = (customizerData) => {
        let highlightedSection = {};
        customizerData.forEach((tab) => {
            Object.values(tab.sections).map((section) => {
                if (section?.highlight !== undefined) {
                    highlightedSection[section?.highlight] = section;
                }
                highlightedSection = {
                    ...highlightedSection,
                    ...SbUtils.getHighlightedSectionRecursive(section)
                }
            })
        })

        return highlightedSection;
    }

    /*
     *   Print Section Highlighter
     *   This will add the blue highlight for the Customizer Preview Sections
    */
    static addHighlighter = (id, text, index = 0, isParent = false) => {
        const { editorActiveSection, editorFeedHighlightedSection, editorHighlightedSection } = useContext(FeedEditorContext);
        return (
            <div
                className='sb-preview-highlight-ctn sb-tr-1'
                data-active={window.highlightedSection === id && index === 0}
                data-dimmed={editorHighlightedSection.hoveredHighlightedSection !== id && editorHighlightedSection.hoveredHighlightedSection !== null ? 'true' : 'false'}
                data-hover={editorHighlightedSection.hoveredHighlightedSection === id && window.highlightedSection !== id}
                data-isparent={isParent}
                onMouseEnter={() => {
                    editorHighlightedSection.setHoveredHighlightedSection(id);
                }}
                onMouseLeave={() => {
                    editorHighlightedSection.setHoveredHighlightedSection(null);
                }}
                onClick={() => {
                    if (editorFeedHighlightedSection[id] !== undefined) {
                        editorActiveSection.setActiveSection(editorFeedHighlightedSection[id])
                    }
                }}
            >
                <span
                    className='sb-preview-highlight-txt'
                    dangerouslySetInnerHTML={{ __html: text.replace(/\s/g, '&nbsp;') }}
                >
                </span>
            </div>
        )
    }

    /*
     *   Creates a tooltip & Display it when hover
     *
    */
    static printTooltip = (text, defaults = {}) => {
        let args = {
            type: defaults.type ?? 'default',
            position: defaults.position ?? 'top-center',
            textAlign: defaults.textAlign ?? 'left',
            width: defaults.width ?? 'default',
            replaceText: defaults.replaceText ?? true
        }
        return (
            <div
                className='sb-tooltip'
                data-type={args.type}
                data-position={args.position}
                data-width={args.width}
                data-textalign={args.textAlign}
                dangerouslySetInnerHTML={{ __html: args.replaceText ? text.replace(/\s/g, '&nbsp;') : text }}
            >
            </div>
        )
    }

    /*
     *   Creates a tooltip & Display it when hover
     *
    */
    static copyToClipBoard = (text, editorNotification) => {
        SbUtils.applyNotification({
            icon: 'success',
            text: __('Copied to Clipboard', 'sb-customizer')
        }, editorNotification)
        const el = document.createElement('textarea');
        el.className = 'sb-copy-clpboard';
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    /*
     *   Stringify JSON Objects
     *
    */
    static stringify = (obj) => {
        return JSON.stringify(obj, (key, value) => {
            if (!isNaN(value) && !Array.isArray(value) && typeof value !== "boolean" && typeof value !== "string") {
                value = Number(value)
            }
            return value
        })
    }


    /*
     *   Check & Parse JSON String
     *
    */
    static jsonParse = (jsonString) => {
        try {
            return JSON.parse(jsonString);
        } catch (e) {
            return false;
        }
    }

    static checkAPIKeys = (noticeBar, topNoticeBar, feedData, isPro, isSocialWallActive) => {
        let newNoticeBar = {};

        if (isPro === false) {
            newNoticeBar = {
                ...newNoticeBar,
                upgradeProNotice: {
                    heading: __('You\'re using TikTok Feeds Lite. To unlock more features consider', 'sb-customizer'),
                    active: true,
                    actionText: __('upgrading to Pro', 'sb-customizer'),
                    type: 'default',
                    close: false,
                    actionClick: () => {
                        window.open("https://smashballoon.com/tiktok-feed/?utm_campaign=tiktok-free&utm_source=lite-upgrade-bar")
                    }
                }
            }
        }

        const params = new URLSearchParams(window.location.search);
        const sw_feed = params.get('sw-feed');
        const feed_id = params.get('feed_id');

        if (isPro && sw_feed && isSocialWallActive == true) {
            let heading = __('Once you are done creating the TikTok Feed, you can go back to Social plugin.', 'sb-customizer'),
                actionText = __('Go to Social Wall', 'sb-customizer'),
                type = 'social-wall';

            if(feed_id) {
                heading = __('TikTok feed successfully created!', 'sb-customizer');
                actionText = __('Go to Social Wall', 'sb-customizer');
                type = 'social-wall-success';
            }

            newNoticeBar = {
                ...newNoticeBar,
                socialWallNotice: {
                    heading: heading,
                    active: true,
                    actionText: actionText,
                    type: type,
                    close: false,
                    actionClick: () => {
                        window.location.href = 'admin.php?page=sbsw#/create-feed'
                    }
                }
            }
        }

        return {
            ...noticeBar,
            topNoticeBar: {
                ...topNoticeBar,
                ...newNoticeBar
            }
        };

    }


    /*
     *   Get Current Context
     *
    */
    static getCurrentContext = () => {
        let currentContext = window.sb_customizer.isFeedEditor ? FeedEditorContext : DashboardScreenContext;
        if (window.sb_customizer.reactScreen === 'settings') {
            currentContext = SettingsScreenContext;
        }
        if (window.sb_customizer.reactScreen === 'aboutus') {
            currentContext = AboutusScreenContext;
        }
        if (window.sb_customizer.reactScreen === 'support') {
            currentContext = SupportScreenContext;
        }
        return currentContext;
    }

    /*
     *   Export JSON to File
    */
    static exportStringToFile = (content, filename, type = "application/json") => {
        const element = document.createElement("a");
        const file = new Blob([content], {
            type,
        });
        element.href = URL.createObjectURL(file);
        element.download = filename;
        document.body.appendChild(element);
        element.click();
    }

    static feedFlyPreview = (editorFeedData, editorTopLoader, editorNotification, sbCustomizer, settingsRef) => {
        const formData = {
            action: 'sbtt_feed_customizer_fly_preview',
            feedID: editorFeedData?.feedData?.feed_info?.id,
            previewSettings: SbUtils.stringify(settingsRef.current),
            feedName: editorFeedData?.feedData?.feed_info?.feed_name,
        },
            notificationsContent = {
                success: {
                    icon: 'success',
                    text: __('Preview updated successfully', 'sb-customizer')
                }
            }
        SbUtils.ajaxPost(
            sbCustomizer.ajaxHandler,
            formData,
            (data) => { //Call Back Function
                if (data?.posts && data?.errors) {
                    editorFeedData.setFeedData({
                        ...editorFeedData.feedData,
                        posts: data?.posts,
                        errors: data?.errors
                    });
                }
            },
            editorTopLoader,
            editorNotification,
            notificationsContent
        )
    }

    static convertDate = (dateString) => {
        const theDate = new Date(dateString);
        return theDate.getTime() / 1000;
    }


    //Save Feed Data
    static saveFeedData = (editorFeedData, editorFeedStyling, editorFeedSettings, sbCustomizer, editorTopLoader, editorNotification, exit = false, isSettingRef = false, getPosts = false) => {
        const formData = {
            action: 'sbtt_builder_update',
            update_feed: true,
            feed_id: editorFeedData.feedData.feed_info.id,
            feed_name: editorFeedData.feedData.feed_info.feed_name,
            feed_style: editorFeedStyling,
            settings: SbUtils.stringify(isSettingRef ? editorFeedSettings.current : editorFeedSettings.feedSettings),
            get_posts: getPosts
        },
            notificationsContent = {
                success: {
                    icon: 'success',
                    text: __('Feed saved succesfully', 'sb-customizer')
                }
            }

        if (SbUtils.checkNotEmpty(localStorage.getItem('newCreatedFeed'))) {
            editorTopLoader = null;
            editorNotification = null;
        }

        SbUtils.ajaxPost(
            sbCustomizer.ajaxHandler,
            formData,
            (data) => { //Call Back Function
                if (getPosts === true) {
                    if (data?.posts) {
                        editorFeedData.setFeedData({
                            ...editorFeedData.feedData,
                            posts: data?.posts
                        });
                    }
                }
                if (exit === true) {
                    window.location.href = sbCustomizer.builderUrl;
                }
                localStorage.removeItem('newCreatedFeed')
            },
            editorTopLoader,
            editorNotification,
            notificationsContent
        )
    }

    static loadMorePosts = async (feedId, page = 2) => {
        const sbtt_feed_options = window?.sbtt_feed_options;

        const formData = {
            action: 'sbtt_load_more_posts',
            nonce: sbtt_feed_options?.nonce,
            feed_id: feedId,
            next_page: page
        }

        return new Promise((resolve, reject) => {
            SbUtils.ajaxPost(
                sbtt_feed_options?.ajaxHandler,
                formData,
                (data) => { //Call Back Function
                    if (data?.posts) {
                        const result = {
                            posts: data?.posts,
                            nextPage: data?.next_page
                        }
                        resolve(result);

                    } else {
                        reject(new Error("No posts found"));
                    }

                },
            )
        })

    }

    //Update Array
    static updateArray = (id, initialArray) => {
        let newArray = [...initialArray];
        if (!newArray.includes(id)) {
            newArray.push(id)
        } else {
            newArray.splice(newArray.indexOf(id), 1);
        }
        return newArray;
    }

    /**
     * Feed counts per tier, used for the feed limit
     * 
     * @returns {object}
     */
    static feedsCountPerTier = () => {
        return {
            'free': 2,
            'basic': 10,
            'plus': 20,
            'pro': 50,
        }
    }

    /**
     * Checks if the feed limit is reached
     * 
     * @returns {boolean}
     */
    static checkFeedLimitReached = (feedsCount) => {
        if(feedsCount === undefined) {
            return false;
        }
        let licenseTier = window?.sb_customizer?.pluginStatus?.license_tier ?? 'free';
        let feedLimit = SbUtils.feedsCountPerTier()[licenseTier] ?? 5;

        return feedsCount >= feedLimit;
    }

    /**
     * Sources per tier, used for the source limit
     * 
     * @returns {object}
     */
    static sourcesPerTier = () => {
        return {
            'free': 1,
            'basic': 5,
            'plus': 10,
            'pro': 25,
        }
    }

    /**
     * Checks if the source limit is reached
     * 
     * @returns {boolean}
     */
    static checkSourceLimitReached = (sourcesCount) => {
        if(sourcesCount === undefined) {
            return false;
        }
        let licenseTier = window?.sb_customizer?.pluginStatus?.license_tier ?? 'free';
        let sourceLimit = SbUtils.sourcesPerTier()[licenseTier] ?? 1;

        return sourcesCount >= sourceLimit;
    }

    /**
     * Checks if combined sources are allowed
     * 
     * @returns {boolean}
     */
    static canCombineSources = () => {
        let licenseTier = window?.sb_customizer?.pluginStatus?.license_tier ?? 'free';
        if(licenseTier === 'free') {
            return false;
        }

        let tieredFeatures = window?.sb_customizer?.tieredFeatures ?? [];
        if (tieredFeatures && tieredFeatures.includes('combine_feed_sources')) {
            return true;
        }

        return false;
    }

    /**
     * Checks if the tier has the feature
     * 
     * @param {string} feature
     * 
     * @returns {boolean}
     */
    static checkTierHasFeature = (feature) => {
        let licenseTier = window?.sb_customizer?.pluginStatus?.license_tier ?? 'free';
        if(licenseTier === 'free') {
            return false;
        }

        let tieredFeatures = window?.sb_customizer?.tieredFeatures ?? [];
        if (tieredFeatures && tieredFeatures.includes(feature)) {
            return true;
        }

        return false;
    }

    /**
     * Map upsell modal to the correct tier
     * 
     * @param {string} upsellModal 
     * @returns  {boolean}
     */
    static mapUpsellModalToTierFeature = (upsellModal) => {
        const tieredFeaturesMap = {
            'loadMoreModal': 'load_more_button',
            'listModal': 'list_layout',
            'masonryModal': 'masonry_layout',
            'carouselModal': 'carousel_layout',
            'filtersModal': 'filter_posts',
            'randomSortModal': 'random_sorting',
            'plusTemplateModal': 'plus_templates',
            'basicTemplateModal': 'basic_templates',
            'proHeaderModal': 'header_stats_info',
            'proPostModal': 'post_stats_info',
            'cardLayoutModal': 'card_layout'
        }

        if (tieredFeaturesMap[upsellModal]){
            return SbUtils.checkTierHasFeature(tieredFeaturesMap[upsellModal]);
        }

        return false;
    }

    /**
     *   Checks if the settings is PRO
     *   then it Opens a Modal
    */
    static checkSettingIsPro = (upsellModal) => {
        if (upsellModal === undefined) {
            return false;
        }

        let isPro = window?.sb_customizer?.isPro && window?.sb_customizer?.isPro ? true : false;
        if (isPro && SbUtils.mapUpsellModalToTierFeature(upsellModal) === true) {
            return false;
        }

        return upsellModal;
    }

    /**
     *   Open Upsell Modal
     *
    */
    static openUpsellModal = (upsellModalType, upsellModal) => {
        upsellModal.setUpsellActive(upsellModalType)
    }

    /*
     *   Prints Pro Label
     *
    */
    static printProLabel = (isPro) => {
        if (isPro) {
            return (
                <span className='sb-pro-label'>PRO</span>
            )
        }
    }

    /**
     * Prints rocket icon
     */
    static printRocketIcon = (isPro) => {
        if(isPro) {
            return (
                SbUtils.printIcon('rocket', 'sb-rocket-svg', false, 12)
            )
        }
    }

}
export default SbUtils;