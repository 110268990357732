import React from 'react';
import ReactDOM from 'react-dom/client'
import Feed from './Frontend/Feed';

const rootEl = document.querySelectorAll(".sbtt-tiktok-feed");
if (rootEl.length > 0) {
    rootEl.forEach((el) => {
        const feedSettings = JSON.parse(el.getAttribute('data-feed-settings'));
        const feedId = el.getAttribute('data-feed-id');
        const posts = JSON.parse(el.getAttribute('data-feed-posts'));
        const headerData = JSON.parse(el.getAttribute('data-feed-header'));
        const nextPage = el.getAttribute('data-next-page');

        const root = ReactDOM.createRoot(el);
        root.render(
            <React.StrictMode>
                <Feed
                    feedSettings={feedSettings}
                    feedId={feedId}
                    posts={posts}
                    headerData={headerData}
                    nextPage={nextPage}
                />
            </React.StrictMode>

        );
    });
}
